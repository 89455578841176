import React, { useState } from "react";
import { Link } from "gatsby";

import ContactForm from "./contactForm";

import * as styles from "./herbs.module.css";

import gradient from "./gradient.svg";
import herbs from "./herbsLogo.svg";

const getSize = (state) => {
  if (state === 0)
    return {
      width: "30%",
      height: "2rem",
    };
  if (state === 1)
    return {
      width: "100%",
      height: "2rem",
    };
  if (state === 2)
    return {
      width: "100%",
      height: "100%",
    };
};

function Herbs() {
  const [state, setState] = useState(0);
  return (
    <div className={styles.container}>
      <div className={styles.link}>
        <Link to="/">Beauty Body Clinic</Link>
      </div>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={herbs} alt="Herbs by JuditH logo" />
      </div>
      <h1>Coming soon</h1>
      <h2>Zanechte nám na Vás kontakt</h2>
      <ContactForm setState={setState} />
      <div className={styles.gradient} style={getSize(state)}>
        <img className={styles.image} src={gradient} alt="Gradient" />
      </div>
    </div>
  );
}

export default Herbs;
