import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import HerbsMain from "../components/herbs/herbs";

const Herbs = (props) => {
  const { errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <>
      <SEO
        title={"Herbs by JuditH"}
        description={"Čínské bylinné směsi pro vaše zdraví a krásu."}
        keywords={[
          "Byliny",
          "Tradiční čínská medicína",
          "Judita Halvová",
          "Herbs by JuditH",
          "Bylinné směsi",
          "Zdraví",
          "Krása",
        ]}
      />
      <HerbsMain />
    </>
  );
};

export default Herbs;
