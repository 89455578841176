import React, { useRef, useEffect } from "react";

import * as styles from "./contactForm.module.css";

function ContactForm({ setState }) {
  const ref = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    const form = ref.current;
    const result = ref2.current;
    const handleSubmit = function (e) {
      e.preventDefault();

      const formData = new FormData(form);
      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      })
        .then(async (response) => {
          let json = await response.json();
          if (response.status == 200) {
            result.innerHTML = "Děkujeme, budeme Vás kontaktovat";
            setState(2);
            result.classList.remove("text-gray-500");
            result.classList.add("text-[var(--dark)]");
          } else {
            console.log(response);
            result.innerHTML = json.message;
            result.classList.remove("text-gray-500");
            result.classList.add("text-gray-500");
          }
        })
        .catch((error) => {
          console.log(error);
          result.innerHTML = "Něco se pokazilo, zkuste to prosím znovu";
        })
        .then(function () {
          form.reset();
        });
    };

    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, [setState]);

  return (
    <form ref={ref} action="https://api.web3forms.com/submit" method="POST">
      <div className={styles.container}>
        <input type="hidden" name="access_key" value={"6773f3bf-2796-4a1d-b742-e5ecd89dc1c1"} />
        <input type="hidden" name="subject" value="Nová zpráva z kontaktního formuláře" />
        <input
          className={styles.input}
          id="kontakt"
          name="Kontakt"
          placeholder="e-mail – telefon"
          onFocus={() => {
            setState(1);
          }}
          onBlur={() => {
            setState(0);
          }}
        />

        <button
          className={`${styles.button}`}
          onFocus={() => {
            setState(1);
          }}
          type="submit"
        >
          Odeslat
        </button>

        <div className={styles.info} ref={ref2}></div>
      </div>
    </form>
  );
}

export default ContactForm;
