import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Herbs from "../templates/herbs";

// export const query = graphql`
//   query HerbsQuery {
//     allServices: allSanityService(sort: { fields: title }) {
//       nodes {
//         title
//         disabled
//         slug {
//           current
//         }
//       }
//     }
//     magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
//       nodes {
//         title
//         slug {
//           current
//         }
//       }
//     }
//     tags: allSanityCategory(sort: { fields: title }) {
//       nodes {
//         title
//         _id
//       }
//     }
//     actions: sanityActions {
//       actions {
//         action {
//           _rawBody
//           title
//           newPrice
//           oldPrice
//           icon
//           addition
//         }
//       }
//     }
//   }
// `;

const HerbsPage = (props) => {
  const { errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Herbs />;
};

export default HerbsPage;
